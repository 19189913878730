module.exports = {
  getRecords: 'Get records successfully',
  insertRecords: 'Records inserted successfully',
  deleteRecords: 'Record deleted successfully',
  badRequest: 'There is an error in your request',
  updateRecords: 'Record updated successfully',
  NoStaff: 'User does not exist',
  perPage: 1,
  emailExist: 'The email address is already exist',
  itemNotExit: 'The selected record does not exist',
  messageSent: 'Message sent successfully',
  BCnotExist: 'The record does not exist in Bigcommerce',
  operationSuccss: 'Operation successfully performed',
}
